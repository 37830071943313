import { useEffect, useState } from 'react'
import Image from 'next/image'
import { useRouter, useSearchParams } from 'next/navigation'
import { LoginForm } from '@components/auth/LoginForm'
import { useAuth } from '@components/auth/store/auth.store'
import { CategoryCarousel } from '@components/containers/categoryCarousel'
import { HeroHome } from '@components/landing/heroHome'
import { AppLayout } from '@components/layout/AppLayout'
import { VideoModal } from '@components/ui/video/VideoModal'
import HeroImage from '@public/assets/images/hero/female-nurse-number-1-right-logo.jpg'
import { useAccountStore } from '@sodium/shared-frontend-schemas'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { firebaseAuthService } from '@src/services/firebase/firebaseAuth.service'
import { sodiumConfig } from '@src/sodiumConfig'

const useLoginFromCustomCode = () => {
  const searchParams = useSearchParams()

  const search = searchParams.get('custom_token')

  useEffect(() => {
    if (search) {
      void firebaseAuthService.signInWithCustomToken(search)
    }
  }, [search])
}

export default function Home() {
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const { user } = useAuth()
  const router = useRouter()
  const routes = useStateAwareRoutes()
  const { selectedAccount } = useAccountStore()
  const isLoggedIn = user?.account

  useLoginFromCustomCode()

  useEffect(() => {
    if (isLoggedIn) {
      router.push(
        routes.dynamic.ACCOUNT_HOME({ accountId: selectedAccount?.id }),
      )
    }
  }, [isLoggedIn, router])

  const showLanding = sodiumConfig.hasLandingPage

  return (
    <AppLayout showFooter={showLanding}>
      {!showLanding && (
        <div
          className="flex min-h-screen items-center justify-center"
          style={{
            background: `radial-gradient(circle, ${sodiumConfig.login.fromRadialColor}, ${sodiumConfig.login.toRadialColor})`,
          }}
        >
          <LoginForm transparentLogoBackground={true} />
        </div>
      )}

      {showLanding && (
        <div
          className="flex-grow text-gray-600"
          style={{ backgroundColor: 'white' }}
        >
          <HeroHome />

          <div>
            <div
              className="relative mb-8 flex justify-center"
              data-aos="zoom-y-out"
              data-aos-delay="450"
            >
              <div className="flex flex-col justify-center">
                <Image
                  className="mx-auto"
                  src={HeroImage.src}
                  width="768"
                  height="432"
                  alt="Hero"
                />
              </div>

              <button
                className="group absolute top-full flex -translate-y-1/2 transform items-center rounded-full bg-white p-4 font-medium shadow-lg"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setVideoModalOpen(true)
                }}
                aria-controls="modal"
              >
                <svg
                  className="h-6 w-6 flex-shrink-0 fill-current text-gray-400 group-hover:text-blue-600"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                  <path d="M10 17l6-5-6-5z" />
                </svg>
                <span className="ml-3">Mira el video (30 seg)</span>
              </button>
            </div>

            {/* Modal */}
            <VideoModal
              id="modal"
              ariaLabel="modal-headline"
              show={videoModalOpen}
              handleClose={() => {
                setVideoModalOpen(false)
              }}
            >
              <div className="pb-9/16 relative">
                {videoModalOpen && (
                  <iframe
                    className="h-[65vh] w-full"
                    src="https://www.youtube.com/embed/6I1uYagwqaA?autoplay=1&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&vq=hd1080"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
            </VideoModal>
          </div>
          <CategoryCarousel
            sectionHeading="Explora..."
            className="p-0 pb-16 md:px-16"
          />
        </div>
      )}
    </AppLayout>
  )
}
